/* ========= Font Variable Start ========= */
$primaryf-family: "Roboto", sans-serif;
$line-height-primary: 1.5;
$primaryfont-size: 16;

/* =========  Colors Variable Start ========= */
$primary-color: #1E1C1C;
$color-white: #ffffff;
$color-black: #000000;
$red-text: #E80000;

/* ========= Background Color ========= */
$primary-bg: #f3f4f6;
$bg-transparent: transparent;
$bg-white: $color-white;
$theme-color: #0063e8;

/* ========= Border Color ========= */
$primary-border: #E4E8EB;

/* ========= Font weight ========= */
$f-weight-400: 400;
$f-weight-normal: normal;
$f-weight-500: 500;
$f-weight-600: 600;
$f-weight-700: 700;
$f-weight-bold: bold;