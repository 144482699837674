// Media Page
.content-box-text{
  border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
width: 81.3%;
margin: auto;
@include lg {
  width: 94%;
}
}

.div-container-button{
  padding-top: 20px;
  padding-bottom: 20px;
text-align: right;
  width: 81.3%;
  margin: auto;
  @include lg {
    width: 94%;
  }

}
 .button-copy{
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  outline: none !important;
  padding: 6px 20px;
  border: none;

  color: #043293;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
@include xsm {
  width: max-content
}

 }
 .copy-button-image{
  display: inline;
 }
 .copy-action{
  display: inline;
  @include xsm {
   display: none;
  }
 
 }
.media-head-text {
  border-radius: 10px;
  background: #FFF;
  color: black;
  padding: 25px !important;
  font-size: 18px;
  @include xsm {
    font-size: 15px;
  }
}

.media-wrapper {
  border-radius: 20px;
  overflow: hidden;
  @include sm {
    border-radius: 16px;
  }
  .media-head {
    background-color: rgba($color-black, 0.5);
    color: $color-white;
    padding: 12px 15px;
    text-align: center;
    @include font-size(16);
    font-weight: $f-weight-500;
    @include sm {
      padding: 10px 15px;
    }
  }
 
  .media-body {
    height: 660px;
    position: relative;
    @include lg {
      height: 600px;
    }
    @include md {
      height: 550px;
    }
    @include sm {
      height: 500px;
    }
    @include xsm {
      height: 400px;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &.audio-body {
      height: auto;
      text-align: center;
      padding: 15px;
      background: #f7f7f7;
    }
    .text-content {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 10px 5px;
    }
  }
  .help-bottom {
    position: absolute;
    bottom: 20px;
    right: 20px;
    text-align: right;
    .help-content {
      position: absolute;
      bottom: 62px;
      right: 0;
      white-space: pre;
    }
    .need-btn {
      background-color: #7f7f7f;
      color: $color-white;
      text-align: center;
      @include font-size(16);
      font-weight: $f-weight-500;
      z-index: 1;
      @include sm {
        padding: 10px 15px;
      }
    }
    .reply-btn {
      background-color: #7f7f7f;
      color: $color-white;
      padding: 10px 15px;
      text-align: center;
      @include font-size(16);
      font-weight: $f-weight-500;
      z-index: 1;
      border-radius: 6px;
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 14px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7px 0 7px;
        border-color: #7f7f7f transparent transparent transparent;
      }
      @include sm {
        padding: 10px 15px;
      }
    }
  }
}
