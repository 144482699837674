@mixin font-size($size) {
  font-size: calculateRem($size);
}

/* ===========================  Transition all 3s ease  =================================== */
@mixin transition-all() {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

/* ===========================  Breakpoints =================================== */
@mixin lg {
  @media (max-width: 1199.98px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 991.98px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 767.98px) {
    @content;
  }
}
@mixin xsm {
  @media (max-width: 575.98px) {
    @content;
  }
}