html,
body {
   @include font-size($primaryfont-size);
   @include sm {
      @include font-size(14);
   }
}
body {
   font-family: $primaryf-family;
   line-height: $line-height-primary;
   color: $primary-color;
   font-weight: $f-weight-400;
   background-color: $bg-white;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   font-smoothing: antialiased;
}

a {
   text-decoration: none !important;
}

h1,
.h1 {
   @include font-size(33);
   font-weight: $f-weight-700;
   margin-bottom: 20px;
   line-height: 1.1;
}
h2,
.h2 {
   @include font-size(33);
   font-weight: $f-weight-700;
   margin-bottom: 20px;
   line-height: 1.1;
}

img {
   max-width: 100%;
   height: auto;
   outline: none !important;
}

ul {
   padding: 0;
   margin: 0;
   li {
      list-style: none;
   }
}

.form-control {
   background-color: $primary-bg;
   border-color: $primary-bg;
   height: 48px;
   @include font-size(21);
   font-weight: $f-weight-400;
   outline: none !important;
   &:focus {
      box-shadow: none;
      border-color: $primary-bg;
      background-color: $primary-bg;
   }
   &:disabled,
   &[readonly] {
      background-color: $primary-bg;
      cursor: not-allowed;
   }
   &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba($primary-color, 0.3);
   }

   &::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba($primary-color, 0.3);
   }

   &:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba($primary-color, 0.3);
   }

   &:-moz-placeholder {
      /* Firefox 18- */
      color: rgba($primary-color, 0.3);
   }
}

.btn {
   @include font-size(21);
   font-weight: $f-weight-700;
   padding: 8px 32px;
   line-height: 24px;
   text-align: center;
   cursor: pointer;
   border-radius: 24px;
   &.btn-primary {
      font-weight: $f-weight-700;
      background-color: $theme-color;
      border-color: $theme-color;
      &:focus {
         box-shadow: none;
      }
   }
   &:disabled,
   .disabled {
      cursor: not-allowed;
   }
}

.input-group {
   .input-group-text {
      background-color: $primary-bg;
      border-color: $primary-bg;
   }
}

.container {
   max-width: 1170px;
   margin: 0 auto;
   padding: 0;
   width: 94%;
   @include sm {
      width: 100%;
      padding: 0 20px;
   }
}

.bg-white {
   background-color: $bg-white !important;
}

// Middle Content
.content-box {
   padding: 65px 0;
   @include sm {
      padding: 45px 0;
   }
}

.content-box-pdf {
   padding-top: 65px ;
   @include sm {
      padding-top: 40px;
   }
}

.center-block {
   width: 100%;
   max-width: 360px;
   margin: 0 auto;
   text-align: left;
   h1,
   h2 {
      margin-bottom: 0.5rem;
   }
   p {
      @include font-size(21);
      line-height: 24px;
      font-weight: $f-weight-500;
      margin-bottom: 24px;
   }
   .btn-primary {
      width: 100%;
      padding: 11px 20px;
   }
}
.date-picker-control-div {
   position: relative;
}
.date-picker-icon {
   position: absolute;
   top: 6px;
   right: 5px;
   border: none;
   background: none;
}
.content-media-info-main-div {
   width: 100%;
   margin: auto;
}
.content-meida-title {
   text-align: left;
   font-weight: 700;
   font-size: 17px;
   color: #000000;
   margin-bottom: 15px;
}
.content-media-desc {
   text-align: left;
   width: 100%;
   height: 98px;
   background: rgba(224, 224, 224, 0.2);
   padding: 10px;
   overflow-wrap: break-word;
   font-weight: 400;
   font-size: 14px;
   color: #000000;
}
