
.otp-blog {
  margin-left: auto;
  margin-right: auto;
  max-width: 384px;
  width: 100%;
  box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 15px 25px 15px;
  border-radius: 5px;
  p {
    @include font-size(13);
    text-align: center;
    line-height: 22px;
    margin-bottom: 0;
    font-weight: $f-weight-400;
  }
}

.otp-section {
  text-align: center;
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
  ul {
    li {
      display: inline-block;
      width: 43px;
      height: 47px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      background: $primary-bg;
      border-radius: 5px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.otp-blog {
  .btn-resend {
    color: $theme-color;
    @include font-size(15);
    font-weight: $f-weight-500;
    text-decoration: underline;
    background: transparent;
    border: none;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.btn-section {
  margin: 20px auto 0;
  text-align: center;
}

.btn {
  &.btn-continue {
    background: $theme-color;
    color: $color-white;
    border: 1px solid $theme-color;
    padding: 10px 38px;
    @include sm {
      padding: 10px 24px;
    }
  }
}

.otp-input {
  &.form-control {
    padding: 0;
    height: 45px;
    width: 45px !important;
    font-size: 24px;
    letter-spacing: 0.69px;
    margin: 0 5px;
    border: none;
    background-color: $primary-bg;
    border-radius: 5px;
    @include sm {
      height: 36px;
      width: 36px !important;
      font-size: 20px;
      margin: 0 3px;
    }
  }
}
