.navbar {
    padding: 15px 50px;
    box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
    @include lg {
        padding: 15px 30px;
    }
    @include sm {
        padding: 15px 20px;
    }
    h1 {
        margin: 0;
        padding: 0;
        line-height: 1;
    }
    .navbar-brand {
        padding: 0;
        img {
            max-width: 169px;
            @include sm {
                max-width: 140px;
            }
        }
    }
}